@import "./variables.scss";

.single-aircraft-container {
    .edit-heading-container {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: end;

        h6 {
            letter-spacing: -0.24px;
        }

        button {
            min-width: 0;
            padding: 0;
        }
    }

    .aircraft-image-container {
        .image-card {
            border-radius: 20px;
            box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            
            

          .img-box{
position: relative;
height: 100%;
width: 100%;

.delete-icon{
    // border: 2px solid red;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    position: absolute;
    top: 0;
    right: 0;
    &:hover{
        opacity: 0.3;
    }
}
img {
    border-radius: 20px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}
.add-img-icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 50px;
    width: 50px;
    // cursor: pointer !important;
}
          }
        }
    }

    .aircraft-description-card {
        margin-top: 20px;
        margin-bottom: 20px;
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
        padding: 10px 20px 20px 15px;

        .description-heading {
            letter-spacing: 0;
            margin-bottom: 10px;
        }

        p {
            line-height: normal;
        }
    }

    .aircraft-details-container {
        border-radius: 20px;
        // border-radius: 20px;
            background: #FFF;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        // border-radius: 20px !important;
        //     background: #FFF !important;
        //     box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;

        .details-table-container {
            padding: 0 !important;
            // border-radius: 20px !important;
                       // border-bottom-left-radius: 20px;
                    // border-bottom-right-radius: 20px;
                    // background: #FFF;
                    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

            // border-radius: 20px !important;
            // background: #FFF !important;
            // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
            // padding: 0 !important;
            .detail-item {
                border-top: 1px solid $border-gray-color;
                //   border-left:1px solid $border-gray-color;
                // border-right: 1px solid $border-gray-color;
                

                // &:first-child {
                //     border-top-left-radius: 20px;
                //     border-top-right-radius: 20px;
                //     background: #FFF;
                //     box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                // }
 
                 &:first-child{
                    border-top: none;
                 }
                &:last-child {
                    // border-bottom-left-radius: 20px;
                    // border-bottom-right-radius: 20px;
                    // background: #FFF;
                    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    // border-bottom: none;
                }

                .item {
                    border-left: 1px solid $border-gray-color;
                    // border:1px solid red;
                    // border-radius: 20px;
                    background-color: #fff;
                    // padding: 5px 20px;
                    min-height: 70px;
                    padding: 0 20px;
                   
                    // padding-top: 20px;
                    // padding-bottom: 20px;
                    // padding-left: 20px;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    .label {
                        color: #4A4A4A;
                        // text-align: center;
                        font-family: Microsoft Sans Serif;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.8px;
                    }
                   
                }
                .top-left{
                    border-top-left-radius: 20px;
                }
                .top-right{
                    border-top-right-radius: 20px;
                }
                .bottom-left{
                    border-bottom-left-radius: 20px;
                }
                .bottom-right{
                    border-bottom-right-radius: 20px;
                }

            }
        }
    }
    .btn-container{
        display: flex;
        align-items: center;
        justify-content: center;
    }

}