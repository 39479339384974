@import "./variables.scss";

.header-container {
  background-color: $primary-color !important;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  margin-bottom: 20px !important;
  // width: 100vw !important;
  .header-content{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    h6{
      color: #FFF;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    // line-height: ; /* 48px */
    letter-spacing: 1.3px;
    }
    button{
      color: $white-color;
    }
  }
}

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media only screen and (min-width: 0px) and (max-width: 320px) {
  .header-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    h6{
      color: #FFF;
    font-family: Montserrat;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 400;
    // line-height: ; /* 48px */
    letter-spacing: 1.3px;
    }
    button{
      color: $white-color;
    }
  }
}

/* smartphones, Android phones, landscape iPhone */
@media only screen and (min-width: 321px) and (max-width: 481px) {
  .header-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    h6{
      color: #FFF;
    font-family: Montserrat;
    font-size: 10px !important;
    text-wrap: wrap;
    font-style: normal;
    font-weight: 400;
    // line-height: ; /* 48px */
    letter-spacing: 1.3px;
    }
    button{
      color: $white-color;
    }
  }
}

/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
@media only screen and (min-width: 482px) and (max-width: 641px) {
  .header-content {
      
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    h6{
      color: #FFF;
    font-family: Montserrat;
    font-size: 14px !important;
    text-wrap: wrap;
    font-style: normal;
    font-weight: 400;
    // line-height: ; /* 48px */
    letter-spacing: 1.3px;
    }
    button{
      color: $white-color;
    }
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media only screen and (min-width: 642px) and (max-width: 961px) {
  .header-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    h6{
      color: #FFF;
    font-family: Montserrat;
    font-size: 20px !important;
    text-wrap: wrap;
    font-style: normal;
    font-weight: 400;
    // line-height: ; /* 48px */
    letter-spacing: 1.3px;
    }
    button{
      color: $white-color;
    }
    
  }
}

