@import "./variables.scss";


.quotation-container {
    
    // .quotation-heading {
    //     h6 {
    //         color: $dark-gray-color;
    //         font-family: Montserrat;
    //         font-size: 18px;
    //         font-style: normal;
    //         font-weight: 500;
    //         line-height: normal;
    //         letter-spacing: 1px;
    //         text-decoration: underline;
    //     }
    // }
    flex: 1;
        .quotation-card {
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
            padding: 15px;

            .user-details {
                .item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                }
                span{
                    min-width: 130px;
                        display: block;  
                }
                .custom-chip{
                    display: inline-flex;
                    margin-bottom: 10px;
                    color: #fff;
                    background: rgba(202, 54, 87, 0.50);
                    border-radius: 30px;
                    min-width: 80px;
                    width: auto;
                    font-size: 12px;
                    letter-spacing: 1px;
                    // width: 100px;
                    align-items: center;
                    min-height: 20px;
                    padding: 3px 15px;
                }
                

                // span {
                //     color: $dark-gray-color;
                //     font-family: Microsoft Sans Serif;
                //     font-size: 18px;
                //     font-style: normal;
                //     font-weight: 400;
                //     line-height: 36px;
                //     min-width: 130px;
                //     display: block;
                //     // border: 1px solid red;
                //     /* 36px */
                // }
            }

            .quotation-details {
                ::-webkit-scrollbar {
                    display: none !important;
                }
.itinerary-details{
    // border: 1px solid red;
    max-height: 200px;
    overflow-y: auto;
    
    .grid-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        &>p{
            width: 50%;
        }

        // p {
        //     color: $dark-gray-color;
        //     font-family: Microsoft Sans Serif;
        //     font-size: 14px;
        //     font-style: normal;
        //     font-weight: 400;
        //     line-height: 30px;
        //     /* 30px */
        //     letter-spacing: 1px;
        // }
    }
}
              



            }

            .total-paid {
                
                h6 {
                    font-weight: 400;
                    margin-bottom: 10px;
                    text-align: center;
                }

                h5 {
                    text-align: center;  
                    font-family: Microsoft Sans Serif;      
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 10px;
                    /* 36px */
                }
            }

            .finalize-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                 button {
                    color: #fff;
                    font-family: Montserrat;
                    font-size: 16px;
                    min-width: 200px;
                    font-style: normal;
                    font-weight: 600;
                    letter-spacing: 0.24px;
                    padding: 3px 20px;
                    // margin-top: 20px;
                    // margin-bottom: 10px;
                }
                
            }
            // .booking-status{
                
            // }
            
        }
    

    
}

.user-quotation-details-card {
    border-radius: 20px !important;
    background: #FFF !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;

   

    .user-detail-item {
        border-bottom: 1px solid $medium-gray-color;
        padding: 10px 20px;
        &:first-child{
            border-top-left-radius: 20px !important;
            border-top-right-radius: 20px !important;
        }
        &:last-child{
            border-bottom-left-radius: 20px !important;
            border-bottom-right-radius: 20px !important;
            border-bottom: none;
        }

        p {
            color: $dark-gray-color;
            font-family: Microsoft Sans Serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            
        }
    }
}
.itinerary-details-container{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
    h6{
        color: $dark-gray-color;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 600;
letter-spacing: 0.24px;
    }
    .edit-container{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        button{
            min-width: 0; 
            padding: 0;
        }
    }
}
.estimated-quotation-card{
    padding-top: 40px !important;
    padding-bottom: 25px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-radius: 20px !important;
background: #FFF !important;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
.estimated-quotation-container{
    display: flex;
    flex-direction: column;
    align-items: center;

    .estimated-amount{
        margin-bottom: 10px;
        p{
            color: $dark-gray-color;
            font-family: Microsoft Sans Serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
      
    }
}
   
    input{
        border-radius: 20px;
border: 1px solid $medium-gray-color;
background: #FFF;
padding: 10px 20px;
    }
    button{
        color: $white-color;
        margin-top: 10px;
        margin-bottom: 10px;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 600;
letter-spacing: 0.24px;
    }
}
.copy-link-card{
    // padding: 3px 10px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
    border-radius: 20px !important;
    padding-left: 10px;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // gap: 10px;
    input{
        width: 100%;
        outline: none;
        border: none;
        background-color: #fff;
    }
    button{
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
    }
}
