@import "./variables.scss";
@import "./header.scss";
@import "./sidebar.scss";
@import "./userManagement.scss";
@import "./fleetManagement.scss";
@import "./airportsManagement.scss";
@import "./quotationManagement.scss";
@import "./signup.scss";
@import "./footer.scss";
@import "./couponManagement.scss";
@import "./commonModal.scss";
@import "./singleAircraft.scss";
@import "./dataGridTable.scss";
@import "./offeredFlight.scss";
@import "./otpInput.scss";
@import "./operatorManagement.scss";
@import "./queryManagement.scss";
@import "./pageNotFound.scss";
@import "./networkError.scss";

body {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.css-1hsz34s-MuiDrawer-docked .MuiDrawer-paper {
  border-radius: 10px !important;
  // border:1px solid red;
}

.main-container {
  padding: 2em 3em;
}

.search-input {
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);

  input {
    padding: 5px 20px 5px 10px;
  }
}

// .rounded-btn-fill{
//   border-radius: 20px !important;
//   background-color: #CA3657 !important;
//   color: #fff !important;
//   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
// }
// .rounded-btn-outlined{
//   border-radius: 20px !important;
// background: $white-color !important;
// color: $dark-gray-color !important;
// box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.25) !important;
// border: none !important;
// }

.rounded-btn-fill {
  border-radius: 20px !important;
  background-color: $secondary-color !important;
  color: $white-color !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;

  &:hover {
    background: $white-color !important;
    color: $secondary-color !important;
  }

  // height: 100% !important;
}

.rounded-btn-outlined {
  border-radius: 20px !important;
  background: $white-color !important;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.25) !important;
  border: none !important;
  color: $dark-gray-color !important;

  &:hover {
    background-color: #CA3657 !important;
    color: #fff !important;
  }
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  border-radius: 20px !important;
  background-color: transparent !important;

}

.tabs-container {
  .MuiTabs-indicator {
    height: 1px;
    background-color: $dark-gray-color !important;
  }

  .css-19kzrtu {
    // padding: 20px 0;
    padding: 0 !important;
    margin-top: 20px;
  }

}

.css-11n9md4-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none !important;
}

.css-11n9md4-MuiInputBase-root-MuiInput-root:after {
  border-bottom: none !important;
}

.css-bd68bi-MuiInputBase-root-MuiInput-root:after {
  border-bottom: none !important;
}

a {
  text-decoration: none !important;
}


.css-19kzrtu {
  // padding: 20px 0 !important;
  padding: 0 !important;
  margin-top: 20px;
}

.modalError {
  color: red !important;
  font-family: Microsoft Sans Serif;
  font-size: 14px !important;
}


::-webkit-scrollbar {
  width: 3px;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  // background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $gray-color;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $dark-gray-color;
}

.pagination-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}


.MuiAutocomplete-inputRoot {
  padding-right: 0 !important;
}


.back-btn-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    height: auto;
    width: 15px;
  }
}

.date-input{
  .MuiOutlinedInput-input{
    padding: 5px 10px !important;
    border: none !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
    // border: 1px solid blue !important;
    border-radius: 20px !important;
border:1px solid gray !important;
    // background: #FFF !important;
    // box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.25) !important;
}
  
}

.MuiDataGrid-root{
  border: none !important;
}

.lds-roller {
  display: inline-flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

#containerLoader {
  margin: auto;
  width: 50px;
  height: 50px;
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  animation-name: global;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.Mui-disabled {
  color: #fff;
  opacity: 0.5;
}
.MuiFormLabel-root{
  background-color: #fff !important;
}
.MuiButtonBase-root:disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

.ck-editor__editable_inline {
  min-height: 200px;
}

.editorFocus{
  .ck-toolbar {
    border: 1px solid rgba(47, 66, 111, 0.30) !important; /* Set your desired border color */
   border-bottom: none !important;
   border-top-left-radius: 20px !important;
   border-top-right-radius: 20px !important;
    // border-radius: 8px !important; /* Set your desired border-radius value */
  }
  .ck-editor__editable_inline {
    border-bottom-left-radius: 20px !important; /* Set your desired border-radius value */
    // overflow: hidden;
    border: 1px solid rgba(47, 66, 111, 0.30) !important;
    border-bottom-right-radius: 20px !important;
    padding-left: 2em !important;
  }
  // .ck.ck-editor__main{
  //   border-radius: 20px !important; /* Set your desired border-radius value */
  // }
  // .ck.ck-editor {
  //   border-radius: 20px !important; /* Set your desired border-radius value */
  // }
  .ck.ck-content.ck-focused {
 /* Set your desired focus border color */
//  border-color: rgba(47, 66, 111, 0.30) !important;
    box-shadow: 0 0 4px #2f426f48 !important; /* Set your desired focus box shadow */
  }
}

.mt-20{
  margin-top: 20px !important;
}

.card-layout{
  border-radius: 20px !important;
  box-shadow: (0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
}

.inputField {
  & .MuiOutlinedInput-root {
    & fieldset {
      border-radius: 20px !important;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
      border: none !important;
    }
      }
}

.inputFile{
  border-radius: 10px !important;
background: #FFF !important;
font-size: 14px !important;
cursor: pointer !important;
border: 1px dashed rgba(47, 66, 111, 0.30) !important;
display: flex !important;
flex-direction: column !important;
justify-content: center !important;
align-items: center !important;
height: 112px !important;
.inputFileText{
  color: #CA3657 !important;
  text-decoration: underline !important;
  padding: 0 !important;
}

}
.small-text{
  font-size: 0.8rem !important;
}