@import "./variables.scss";
.airports-container{
//   .airport-heading{
//     h6{
//         color: $dark-gray-color;
//         font-family: Montserrat;
//         font-size: 18px;
//         font-style: normal;
//         font-weight: 500;
//         line-height: normal;
//         letter-spacing: 1px;
//         text-decoration: underline;
//     }
    
//   }
  .filter-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
   
    .filter-item{
        display: flex;
        // border:1px solid red;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
       
        .MuiSlider-root{
            width: 30%;
        }
    }
    .flex-end{
        justify-content: flex-end;
    }
  }
  .airports-table-container{
    border-radius: 20px;
background: #FFF;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
// thead{
//     tr{
//         th{
//             color: $dark-gray-color;
// font-family: Montserrat;
// font-size: 16px;
// font-style: normal;
// font-weight: 600;
// line-height: normal;
// letter-spacing: 0.24px;
//         }
//     }
// }
}
}

