@import "./variables.scss";

.network-error-container {
  width: 60%;

  // min-height: 100vh;
  // border: 1px solid red;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  h3 {
    color: $dark-gray-color;
    text-align: center;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 140.184%; /* 56.073px */
    letter-spacing: 2px;
  }
  p {
    color: $dark-gray-color;
    text-align: center;
    font-family: Microsoft Sans Serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.184%; /* 44.859px */
    letter-spacing: 1.6px;
    margin: 20px 0;

    a{
        color: $secondary-color;
    }
  }

  .center-content {
    // border: 4px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .img {
      height: 200px;
      width: 100%;
      // border: 2px solid green;
      img {
        height: 100%;
        width: 100%;
      }
    }
    button {
      margin-top: 10px;
    }
  }
}

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media only screen and (min-width: 0px) and (max-width: 320px) {
    .network-error-container {
        width: 90%;
      
        // min-height: 100vh;
        // border: 1px solid red;
      
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        h3 {
          font-size: 20px;
          letter-spacing: 1px;
        }
        p {
          font-size: 14px;
          letter-spacing: 0.8px;
          margin: 15px 0;
        }
      
        .center-content {
          .img {
            height: 150px;
          }
          button {
            margin-top: 10px;
          }
        }
      }
}

/* smartphones, Android phones, landscape iPhone */
@media only screen and (min-width: 321px) and (max-width: 481px) {
    .network-error-container {
        width: 90%;
      
        // min-height: 100vh;
        // border: 1px solid red;
      
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        h3 {
          font-size: 20px;
          letter-spacing: 1px;
        }
        p {
          font-size: 14px;
          letter-spacing: 0.8px;
          margin: 15px 0;
        }
      
        .center-content {
          .img {
            height: 150px;
          }
          button {
            margin-top: 10px;
          }
        }
      }
}

/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
@media only screen and (min-width: 482px) and (max-width: 641px) {
    .network-error-container {
        width: 70%;
      
        // min-height: 100vh;
        // border: 1px solid red;
      
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        h3 {
          font-size: 20px;
          letter-spacing: 1px;
        }
        p {
          font-size: 14px;
          letter-spacing: 0.8px;
          margin: 15px 0;
        }
      
        .center-content {
          .img {
            height: 150px;
          }
          button {
            margin-top: 10px;
          }
        }
      }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media only screen and (min-width: 642px) and (max-width: 961px) {
    .network-error-container {
        width: 60%;
      
        // min-height: 100vh;
        // border: 1px solid red;
      
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        h3 {
          font-size: 20px;
          letter-spacing: 1px;
        }
        p {
          font-size: 14px;
          letter-spacing: 0.8px;
          margin: 15px 0;
        }
      
        .center-content {
          .img {
            height: 150px;
          }
          button {
            margin-top: 10px;
          }
        }
      }
}
