@import "./variables.scss";



.coupons-tab{
    .coupons-filter-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .coupon-input {
            border-radius: 10px;
            border: 1px solid #808080;
            background: #FFF;
        
            input {
                padding: 0;
            }
        }
    }
    
    .coupons-container {
        margin-top: 2em !important;

       
          
          
        .coupon-card {
            // display: flex;
            // // flex-direction: column;
            // height: 100%; 
            display: flex;
            flex-direction: column;
            height: 100%; 
            padding: 10px;
            border-radius: 20px;

            background: #FFF;
            box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
           
              
             
        
    
            img {
                height: 150px;
                width: 100%;
                display: block;
                object-fit: cover;
                border-radius: 5px;
            }
    
            .coupon-details {
                flex-grow: 1; 
                h6 {
                    font-size: 16px !important;
                }
    
                p {
                    line-height: 25px;
                }
            }
        }
    }
}

.add-coupon-container{
    .add-coupon-card{
        border-radius: 10px;
        border: 1px dashed $dark-gray-color;
        padding: 10px;
        .coupon-image{
            background-color: $very-light-gray-color;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 200px;
        }
        .upload-coupon{
           display: flex;
           justify-content: center;
           align-items: center !important;
        //    padding-right: 4em;
        //    background-color: red;
           height: 100%;
           .image-tilte{
            height: 100%;
            display: flex;
            align-items: flex-end;
            border-bottom: 1px solid $dark-gray-color;
           }
        }
    }
}

.coupons-details-container{

    .edit-container{
        display: flex;
        align-items: center;
        margin-left: 20px;
        margin-right: 10px;
        .css-13calvb-MuiButtonBase-root-MuiButton-root{
            min-width: 0;
        }
    }
    .coupon-img-card{
        height: 250px;
        width: 100%;
        // border: 10px solid red;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}
