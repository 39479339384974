
.data-grid-table{
    // height: 400px !important;
    width: 100% !important;
    border-radius: 20px !important;
background: #FFF !important;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
// .css-ympt4w-MuiDataGrid-root{
//     border-radius: 20px;
// }
.css-tq4irp-MuiDataGrid-root.MuiDataGrid-autoHeight{
    border-radius: 20px;
}
.css-13calvb-MuiButtonBase-root-MuiButton-root{
    min-width: 0;
}
    // border:1px solid blue;
    .MuiDataGrid-columnHeaderTitle{
        color: red;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        text-align: left;
        padding: 16px;
        color: #4A4A4A;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.24px;
    }
    
    .MuiDataGrid-cellContent{
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        text-align: center;
        padding: 16px;
        color: #4A4A4A;
        font-family: Microsoft Sans Serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.2px;
    }
}


