@import '../../style/sass/variables.scss';





    .modalContainer {
        position: relative !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        width: 90% !important;
        max-height: 90vh !important;
        background-color: $white-color !important;
        overflow-y: auto !important;
        overflow-x: hidden;
        box-shadow: 24 !important;
        outline: none !important;
        border-radius: 10px !important;
    
        .modalCloseBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0px;
            top: 0px;
            height: 20px;
            width: 20px;
            min-width: 0;
            padding: 20px;
            background-color: #F8F8F8;
            color: #CA3657;
            border-radius: 50%;
        }
    }
    .modal-table-container{
        .heading{
          margin: 10px 0 !important;
          margin-top: 20px !important;
        }

        .edit-detail-item{
          border-top:1px solid $border-gray-color;
          &:last-child{
            border-bottom:1px solid $border-gray-color;
          }
          
          .edit-item{
            
            padding-top:5px;
            padding-bottom: 5px;
            padding-left: 20px;
            display: flex;
            justify-content: start;
            align-items: center;
          }
          .dark-bg{
            background-color: $very-light-gray-color;
          }
        }
      }
      .btn-container{
        margin-top: 20px !important;
        margin-bottom: 10px !important;
      }



.container{
    padding-bottom: 100px;
margin-bottom: 100px;
margin-left: 10px;
}

.ItenaryContainer{
    background-color: $white-color;
padding: 3px;
border-radius: 20px;
}
.dateInput {
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #E1E1E1;
    padding-left: 10px;
}

.depatureArrivalAirportBox {
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #E1E1E1;
    padding: 0;
    .MuiInputBase-root {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    

    .unionIcon {
        display: none;
    }
}
.JobType {
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #E1E1E1;
    padding: 0;
    
   
}
.searchInput {
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #E1E1E1;
    background: $white-color;
}
.quotationInput {
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #E1E1E1;
    background: $white-color;
    padding-left: 8px;
}
.quotationCurrency{
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #E1E1E1;
    background: $white-color;
}
.addAircraftBtn{
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.addItenaryBtn{
    position: relative;
    display: flex;
    align-content: flex-end;
    justify-content: center;
    padding: 20px;
    margin-left: -20px;
}
.rmvItenaryBtn{
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
}
.downloadBtn,.sendBtn{
display: flex;
justify-content: center;

Button{

    background-color: $white-color;
    border-radius: 20px;    
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);width: 100%;
padding: 20px;
}
}
.downloadBtn{
    margin-top: -68px;
}
.dlSndBtn{
 display: flex;
 justify-content: space-between;
 position: relative;
}
.validity{
    position: relative;
}
.aircraftError{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
}
.centerItem{
    display: flex;
    justify-content: center;
}
@media only screen and (min-width: 480px) {
   .container{
    padding: 10px;
    margin-left: 0px;

   }
    .ItenaryContainer{
    padding-right: 16px;
    margin: 10px;
    }
    .addItenaryBtn{
        position: relative;
        background-color: transparent;
        justify-content: flex-end;
    }
}
@media only screen and (min-width: $tablet) {
    .modalContainer{
        padding: 20px;
    }


}
@media only screen and (min-width: $laptop) {

    .depatureArrivalAirportBox {
        border-radius: 30px;
        background: $white-color ;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        position: relative;
        width: 100%;
        padding: 10px 30px 10px 25px;
        
       
    }
    .JobType {
        border-radius: 30px;
        background: $white-color ;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        position: relative;
        width: 100%;
        padding: 5px 30px 5px 25px;
        
       
    }
    .dateInput {
        height: 100%;
        display: flex;
        gap: 10px;
        align-items: center;
        color: inherit;
        width: 100%;
        border-radius: 30px;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-size: 16px;
        padding: 10px 30px 10px 25px;
    }
    .searchInput {
        border-radius: 30px;
        background: $white-color;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 10px 30px 10px 25px;
        width: 49.5%;

        
    }
    .quotationInput {
        border-radius: 30px;
        background: $white-color;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 10px 30px 10px 25px;


        
    }
    .quotationCurrency {
        border-radius: 30px;
        background: $white-color;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 10px 30px 10px 25px;


        
    }
    .quotationEditCurrency {
        border-radius: 30px;
        background: $white-color;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        // padding: 10px 30px 10px 25px;


        
    }
    .validity{
        display: flex;
        flex-direction: row;
    }
    .flightDuration{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

}

