@import "./variables.scss";

.table-container{
    // padding: 1em;
    // padding-left: 4em;
    border-radius: 0 !important;
background: transparent !important;
box-shadow: none !important;
  // thead{
  //   tr{
  //       th{
  //           // color: $dark-gray-color;
  //           font-family: Montserrat;
  //           font-size: 18px;
  //           font-style: normal;
  //           font-weight: 600;
  //           line-height: normal;
  //           letter-spacing: 0.24px
  //       }
  //   }
  // }
//   tbody{
//     tr{
//       th{
// letter-spacing: 0.2px;
//       }
//     }
//   }
  button{
    min-width: 0;
    padding: 4px;
  }
}