@import "./variables.scss";

.footer-container {
position: sticky;
 bottom: 0;
 width: 100%;
    background: $primary-color !important;
    box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;
    padding: 10px !important;
   

    .img {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    p {
        text-align: center;
        color: $white-color;
        text-shadow: 0px 1px 0px 0px #000;
        font-family: Microsoft Sans Serif;
        font-size: 16px;
        font-style: normal ;
        font-weight: 100 !important;
        letter-spacing: 1.2px;
        margin-top: 10px;
    }
}