@import "./variables.scss";

// .fleets-container{
//     .fleet-heading{
//         h6{
//             color: $dark-gray-color;
// font-family: Helvetica Medium;
// font-size: 18px;
// font-style: normal;
// font-weight: 500;
// line-height: normal;
// letter-spacing: 1px;
// text-decoration: underline;
//         }
//     }

.fleets-container {
    margin-top: 1em;

    .fleet-card {
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
        padding: 10px;

        &>img {
            border-radius: 10px;
        }


        .css-46bh2p-MuiCardContent-root {
            padding: 0;
        }

        .card-content {
            padding: 10px 0;

            h6,
            p {
                margin-bottom: 20px;
            }

            .btns-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

    }
}

// }
.add-fleet-container {
    

    // background: #F5F5F5 !important;
    

    .btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

.fleet-image-card {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
        border-radius: 10px !important;
        // background: #FFF !important;
        background: #F5F5F5;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
       

        .add-img {
            cursor: pointer;
        }

        .card-img {
            cursor: pointer;
            object-fit: cover;
            // object-fit: contain;
            height: 100%;
            width: 100%;
        }
    }