$em-1: 1em;
$em-2: 2em;
$em-3: 3em;
$em-5: 5em;
$em-10: 10em;
$em-15: 15em;
$em-25: 25em;
$em-50: 50em;
// pixel value

$px-5: 5px;
$px-10: 10px;
$px-15: 15px;
$px-20: 20px;
$px-25: 25px;
$px-30: 30px;

// rem value
$rem-1: 1rem;
$rem-2: 2rem;
$rem-3: 3rem;
$rem-5: 5rem;
$rem-0-5: 0.5rem;
$rem-1-5: 1.5rem;

// color-code
$primary-color:#333333;
$secondary-color:#CA3657;
$white-color:#fff;
$black-color:#000;
$yellow-color:#FFC700;
$dark-gray-color:#4A4A4A;
$gray-color:#949494;
$medium-gray-color:#808080;
$light-gray-color:#F8F8F8;
$very-light-gray-color:#F5F5F5;
$border-gray-color:#C3C3C3;



$small-mobile:350px;
$large-phone:600px;
$tablet:768px;
$laptop:992px;
$large-laptop:1200px;
