@import "./variables.scss";

.sidebar-container{
border-radius: 10px !important;
box-shadow: (0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
}
.sidebar-list{
    display: flex !important;
    flex-direction: column !important;
    gap: 15px;
    align-items: center;
    padding: 20px 0;
    border-radius: 10px;
    
    .MuiPaper-root{
    border-radius: 40px;
    }
   
      
    .list-item{
        button{
            height: 37px;
            max-width: 35px !important;
            min-width: unset;
        }
        .selectedTab{
            border-radius: 10px;
          border: 2px solid #CA3657;
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
          }  
    }
}